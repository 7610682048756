import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'


const Banner = (props) => (

    <section id="banner" className="major parallax">
        <div className="inner">
            <header className="major">
                <h1>Melancholia</h1>
            </header>
            <div className="content">
                <ul className="actions">
                    <li><AnchorLink href="#main" className="button music">Listen</AnchorLink></li>
                </ul>
            </div>

        </div>

    </section>

)

export default Banner
