import React from 'react'
import '../assets/scss/layout/_harmonycavern.scss'

const HarmonyCavern = props => (
  <section id="HarmonyCavern" className="harmonycavernbg major">
    <div className="inner">
      <h3>💴🏙💙 Buy or Stream 💙🏙💴</h3>
      <div className="grid-wrapper">
        <div className="col-3">
          <a
            href="https://open.spotify.com/album/1Nu8geDXPXTwrnYqcqU2ws?si=91S5rQe1TPmM7sHF2CtcXg"
            target="_blank"
            className="button storebutton spotify"
          >
            Stream
          </a>
          <a
            href="https://music.apple.com/us/album/melancholia-ep/1482342906?app=music&ign-mpt=uo%3D4"
            target="_blank"
            className="button storebutton applemusic"
          >
            Stream
          </a>
        </div>

        <div className="col-3">
          <a
            href="https://sebrene.bandcamp.com/releases"
            target="_blank"
            className="button storebutton bandcamp"
          >
            Buy
          </a>
          <a
            href="https://music.apple.com/us/album/melancholia-ep/1482342906?app=itunes"
            target="_blank"
            className="button storebutton itunes"
          >
            Buy
          </a>
        </div>

        <div className="col-3">
          <a
            href="https://www.amazon.com/gp/product/B07YQ8GW5B"
            target="_blank"
            className="button storebutton amazon"
          >
            Buy
          </a>
          <a
            href="https://play.google.com/store/music/album/Sebrene_Melancholia?id=B7budr7nuh3r67mgwhro4o4i2f4"
            target="_blank"
            className="button storebutton googleplay"
          >
            Buy
          </a>
        </div>

        <div className="col-3">
          <a
            href="https://tidal.com/browse/album/119427123"
            target="_blank"
            className="button storebutton tidal"
          >
            Stream
          </a>
          <a
            href="https://soundcloud.com/sebrene/sets/melancholia-ep"
            target="_blank"
            className="button storebutton soundcloud"
          >
            Stream
          </a>
        </div>
      </div>
      <div className="all">
        Also available on: TikTok, YouTube, Pandora, Deezer, Tidal, Napster,
        iHeartRadio, ClaroMusica, Saavn, Anghami, KKBox, MediaNet
      </div>
    </div>
  </section>
)

export default HarmonyCavern
