import React from 'react'

const Credits = props => (
  <section id="Credits">
    <div className="inner minustop"><h3>Thank Yous</h3>
      <div className="grid-wrapper">
        
        <div className="col-3 marginbreak">Thanks to my old bandmate and forever brother Landon Jones for tracking vocals on my nonexistent budget, give him all of the love on his (<a href="https://twitter.com/Landon82858" target="_blank">Twitter</a>) + (<a href="https://www.instagram.com/thelandonjones/" target="_blank">Instagram</a>)</div>
        <div className="col-3 marginbreak">Thank you to Andrew Vannoy for letting me borrow his bass for me to record, letting me pick from any of his children no hesitation</div>
        <div className="col-3 marginbreak">Thanks to Davon Barnnette my weeaboo ally for letting me borrow his AT2035 microphone to record for as long as I wanted</div>
        <div className="col-3 marginbreak">And thank you to my roommate Logan Jones for dealing with my shit for a long while having to listen to my terrible music over and over again</div>
      </div>
    </div>
  </section>
)

export default Credits
