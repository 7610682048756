import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import HarmonyCavern from '../components/HarmonyCavern'
import WavesComponent from '../components/WavesComponent'
import Credits from '../components/Credits'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Melancholia - Sebrene"
          meta={[
            { name: 'description', content: 'Melancholia - Sebrene' },
            { name: 'keywords', content: 'music, ep, melancholia, sebrene' },
          ]}
        ></Helmet>

        <Banner />

        <div id="main">
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-6">
                <h3>Hi~</h3>
                <p>
                  I came out with a thing. Have a listen, it would mean the
                  world to me. Available on all platforms, and you can buy if
                  you want to help make this idiot extra happy.
                </p>
                <p> - Andy</p>
              </div>

              <div className="col-6">
                <iframe
                  width="100%"
                  height="350"
                  scrolling="no"
                  frameborder="no"
                  allow="autoplay"
                  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/886696351%3Fsecret_token%3Ds-ttjmD&color=%23ef6fc9&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true"
                ></iframe>
              </div>
            </div>
          </div>
          <WavesComponent />
        </div>
        <HarmonyCavern />
        <Credits />
      </Layout>
    )
  }
}

export default HomeIndex
